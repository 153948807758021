.absoluteFill {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ReactSuperMario .Touchable {
  transition: left 150ms ease-out;
  position: absolute;
  z-index: 2;
}

