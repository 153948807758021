body, html {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
  position: relative;
}

#root {
  height: 100%;
  display: flex;
}
